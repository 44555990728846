<template>
  <div>
    <el-form
      :model="ruleForm"
      :label-position="labelPosition"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="选择项目" prop="projectId">
            <el-select
              style="width: 100%"
              v-model="ruleForm.projectId"
              disabled
              placeholder="请选择项目"
            >
              <el-option
                v-for="item in projectList"
                :value="item.projectId"
                :label="item.projectName"
                :key="item.projectId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="场地类型" prop="radioSiteType">
            <el-radio-group class="siteType" v-model="ruleForm.radioSiteType">
              <p><el-radio label="basketball" disabled>篮球次卡</el-radio></p>
              <p><el-radio label="tennis" disabled>网球次卡</el-radio></p>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="次卡名称" prop="cardName">
            <el-input v-model="ruleForm.cardName"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="是否开放">
            <el-switch v-model="value"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="常规开放时间">
            <el-collapse v-model="conventionTime" accordion>
              <el-collapse-item
                v-for="(item, index) in weeks"
                :key="index"
                :title="item.openWeek"
                :name="index"
              >
                <div>
                  <el-form-item label="当天是否开放">
                    <el-switch
                      v-model="item.status"
                      @change="
                        item.status == false ? (item.money = '') : item.money
                      "
                    ></el-switch>
                  </el-form-item>
                  <div v-show="item.status === true" class="mo-input--number">
                    <el-input-number
                      :min="0"
                      :max="9999999"
                      :controls="false"
                      v-model="item.money"
                    ></el-input-number>
                    <div class="define-append">元</div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="特殊开放时间">
            <div class="block" style="margin-bottom: 10px">
              <el-date-picker
                v-model="ruleForm.openTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button type="primary" plain @click="addspecialWeeksList"
                >新增日期</el-button
              >
            </div>
            <el-collapse v-model="specialTime" accordion>
              <el-collapse-item
                v-for="(item, index) in specialWeeks"
                :key="index"
                :name="index"
              >
                <template slot="title">
                  {{ item.openTime }}
                  <el-button
                    type="text"
                    @click.stop="handleDelete(index)"
                    style="position: absolute; right: 10px"
                    >删除日期</el-button
                  >
                </template>
                <div>
                  <el-form-item label="当天是否开放">
                    <el-switch v-model="item.status"></el-switch>
                  </el-form-item>
                  <div v-show="item.status === true" class="mo-input--number">
                    <el-input-number
                      :min="0"
                      :max="9999999"
                      :controls="false"
                      v-model="item.money"
                    ></el-input-number>
                    <div class="define-append">元</div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </el-col>
      </el-row>
      <el-col>
        <el-row>
          <div class="SpecialTime">
            <p @click="getHistoryTime" v-if="!flag">历史特殊开放时间</p>
            <el-collapse v-model="historySpecialTime" v-if="flag" accordion>
              <el-collapse-item
                v-for="(item, index) in historyspecialWeeks"
                :key="index"
                :name="index"
              >
                <template slot="title">
                  {{ item.openTime }}
                </template>
                <div>
                  <el-form-item label="当天是否开放">
                    <el-switch
                      v-model="item.status"
                      active-value="ON"
                      inactive-value="OFF"
                      disabled
                    ></el-switch>
                  </el-form-item>
                  <div style="width: 150px; margin-top: 8px">
                    <el-input placeholder="" v-model="item.money" disabled>
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <p v-if="flag" style="margin-top: 10px" @click="getHistoryTime">
              查看更多
            </p>
          </div>
        </el-row>
      </el-col>
    </el-form>
  </div>
</template>
<script>
import { getCardHistoryList } from "@/api/ruge/lego/stadium";
export default {
  props: {
    detailsObj: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      editObj: {},
      labelPosition: "top",
      conventionTime: "-1",
      specialTime: "-1",
      historySpecialTime: "1",
      flag: false,
      current: 1,
      value: "",
      startTime: "",
      endTime: "",
      input2: "",
      weeks: [
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周一",
          status: false,
          money: "",
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周二",
          status: false,
          money: "",
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周三",
          status: false,
          money: "",
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周四",
          status: false,
          money: "",
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周五",
          status: false,
          money: "",
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周六",
          status: false,
          money: "",
        },
        {
          openTimeType: "normal",
          openTime: "",
          openWeek: "周日",
          status: false,
          money: "",
        },
      ],
      specialWeeks: [],
      historyspecialWeeks: [],
      ruleForm: {
        projectId: null,
        delivery: false,
        RegularOpoen: true,
        openTime: "",
        radioSiteType: "",
        cardName: "",
      },
      rules: {
        projectId: [{ required: true, message: "请选择项目", trigger: "blur" }],
        RegularOpoen: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
        radioSiteType: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
        cardName: [
          { required: true, message: "请填写名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    initEditData() {
      console.log(this.editObj, "this.editObj");
      let editObj = this.editObj;
      this.ruleForm.radioSiteType = editObj.cardType;
      this.ruleForm.cardName = editObj.cardName;
      this.ruleForm.projectId = editObj.projectId;
    },
    addspecialWeeksList() {
      console.log(111);
      const addList = {
        id: "",
        cardId: "",
        openWeek: "",
        openTime: this.ruleForm.openTime,
        timeSlot: null,
        openTimeType: "special",
        money: "",
        status: false,
        createdBy: "",
        creationDate: "",
        lastUpdatedBy: "",
        lastUpdateDate: "",
      };
      if (this.ruleForm.openTime !== "") {
        this.specialWeeks.push(addList);
        this.ruleForm.openTime = "";
      }
      console.log(this.specialWeeks, "this.specialWeeks");
    },
    handleDelete(index) {
      this.specialWeeks.splice(index, 1);
    },
    getHistoryTime() {
      getCardHistoryList({
        cardId: this.detailsObj.id,
        current: this.current,
        rowCount: 10,
      }).then((res) => {
        console.log(res, "res");
        let historyTimeList = res.rows;
        this.flag = true;
        if (this.current === 1) {
          this.historyspecialWeeks = historyTimeList;
        } else {
          this.historyspecialWeeks =
            this.historyspecialWeeks.concat(historyTimeList);
        }
        // 增加当前页数
        this.current++;
        // 如果没有更多数据可供显示，则禁用“查看更多”按钮
        if (!historyTimeList.length) {
          this.$message("没有更多数据了~");
        }
      });
    },
  },
  watch: {
    "detailsObj.siteCardManageTimeList"(newVal, oldVal) {
      if (newVal) {
        const specialWeeks = newVal.filter((j) => j.openTimeType === "special");
        console.log(specialWeeks, "specialWeeks");
        specialWeeks.forEach((j) => {
          j.status = j.status === "ON";
        });
        this.specialWeeks = specialWeeks;

        newVal.forEach((i) => {
          const foundWeek = this.weeks.find(
            (item) =>
              item.openWeek === i.openWeek && i.openTimeType === "normal"
          );
          if (foundWeek) {
            Object.assign(foundWeek, i);
            foundWeek.status = i.status === "ON";
          }
        });
      }
    },
    "detailsObj.cardStatus"(newVal, oldVal) {
      if (newVal === "ON") {
        this.value = true;
      } else {
        this.value = false;
      }
    },
    detailsObj: {
      handler(newVal) {
        // 将新的 props 数据拷贝到 editObj 中
        this.editObj = Object.assign({}, newVal);
        // console.log('监听的editObj', this.editObj);
        this.initEditData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.siteType {
  display: flex;
  width: 100%;

  p:first-child {
    margin-right: 15px;
  }

  p {
    width: 100px;
    padding: 10px 10px;
    border: 1px solid #dcdfe5;
    border-radius: 5px;
  }
}
.SpecialTime {
  margin-top: 10px;

  p {
    font-size: 12px;
    color: #006bd9;
    cursor: pointer;
    margin-bottom: 12px;
  }
}
::v-deep .el-collapse-item__arrow {
  margin: 0;
}

::v-deep .el-collapse-item__content {
  display: flex;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  margin-right: 6px;
}

::v-deep .el-form-item .el-form-item {
  display: flex;
}

::v-deep .el-form--label-top .el-form-item__label {
  margin-right: 11px;
}
// 隐藏输入框加减按钮---------
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
// ---------

/* 自定义数字输入框append  */
.mo-input--number {
  border: 1px solid #dcdfe6;
  width: 100%;
  display: flex;
  border-radius: 4px;
  .el-input-number--mini {
    flex: 1;
  }
  ::v-deep .el-input__inner {
    border: none !important;
  }
  ::v-deep .el-input-number--medium {
    width: 110px;
  }
}

.define-append {
  width: 50px;
  display: inline-block;
  background: #f5f7fa;
  padding: 0px 3px;
  border-left: none;
  height: 36px;
  line-height: 36px;
  color: #909399;
  font-size: 12px;
  text-align: center;
}
</style>
 
